import { useEffect, useState } from 'react'

const useCountdown = (miliseconds: number): number => {
  // initialize timeLeft with the miliseconds prop
  const [timeLeft, setTimeLeft] = useState(miliseconds - Date.now())

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1000)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId)
    }
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft])

  useEffect(() => {
    setTimeLeft(miliseconds - Date.now())
  }, [miliseconds])

  return timeLeft
}

export default useCountdown
