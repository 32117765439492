import React, { HTMLProps, RefObject } from 'react'
import cn from 'classnames'

import ConditionalWrapper from 'components/ConditionalWrapper/ConditionalWrapper'

import styles from './CtaLink.module.css'

interface CtaLinkProps extends HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode
  href?: string
  className?: string
  lightColor?: boolean
  stylesOnly?: boolean
}

const CtaLink = (
  {
    children,
    href,
    className,
    lightColor = false,
    stylesOnly = false,
    ...props
  }: CtaLinkProps,
  ref: RefObject<HTMLAnchorElement>,
) => {
  return (
    <span
      className={cn(className, styles.ctaLink, {
        [styles.lightColor]: lightColor,
      })}
    >
      <ConditionalWrapper
        condition={!stylesOnly}
        wrapper={(wrappedChildren: React.ReactNode) => (
          <a href={href} ref={ref} {...props}>
            {wrappedChildren}
          </a>
        )}
      >
        {children}
      </ConditionalWrapper>
    </span>
  )
}

export default React.forwardRef<HTMLAnchorElement, CtaLinkProps>(CtaLink)
