import React, { useEffect, useState } from 'react'
import { Typography } from 'antd'
import type { CustomerCouponsQuery } from 'apollo-gql'
import cn from 'classnames'
import { useTranslation } from 'lib/i18n'
import { isServer } from 'lib/utils'
import Image from 'next/image'

import useCustomerCoupons from 'hooks/useCustomerCoupons'
import useRemainingTime from 'hooks/useRemainingTime'
import useRouter from 'hooks/useRouter'

import clockIcon from './clockIcon.svg'

import styles from './DiscountCoupon.module.css'

const { Text } = Typography

interface DiscountCouponProps {
  coupon?: CustomerCouponsQuery['customerCoupons'][0]
  onClickBookButton?: () => void
  design:
    | 'dark-red'
    | 'red'
    | 'green'
    | 'dark-gold'
    | 'dark-rainbow'
    | 'rainbow'
    | 'teal'
  subject?: string
  description?: string
}

const selectBestCoupon = ({
  coupons,
}: {
  coupons: CustomerCouponsQuery['customerCoupons'][0][]
}) => {
  if (coupons && coupons.length) {
    const activeCoupons = coupons.filter(
      (coupon) => new Date(coupon.expiresAt) > new Date(),
    )
    const sortedCoupons = activeCoupons.sort((a, b) =>
      a.discountPercentage < b.discountPercentage ? 1 : -1,
    )
    return sortedCoupons[0]
  }
  return null
}

const DiscountCoupon: React.FC<DiscountCouponProps> = ({
  coupon,
  design = 'rainbow',
  onClickBookButton,
  subject,
  description,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { coupons } = useCustomerCoupons()

  const [selectedCoupon, setSelectedCoupon] = useState<
    CustomerCouponsQuery['customerCoupons'][0]
  >({
    expiresAt: '',
    code: '',
  })

  const remainingTime = useRemainingTime({
    expiresAt: selectedCoupon.expiresAt,
  })
  const remainingTimeFormatted = isServer()
    ? ''
    : remainingTime.remainingTimeFormatted

  useEffect(() => {
    if (coupon) {
      setSelectedCoupon(coupon)
    } else if (!isServer()) {
      const bestCoupon = selectBestCoupon({ coupons })
      if (bestCoupon) {
        setSelectedCoupon(bestCoupon)
      }
    }
  }, [coupon, coupons])

  if (
    selectedCoupon.expiresAt &&
    new Date(selectedCoupon.expiresAt) < new Date()
  ) {
    return null
  }

  if (
    !selectedCoupon.code ||
    !selectedCoupon.discountPercentage ||
    !selectedCoupon.expiresAt
  ) {
    return null
  }

  const onClick = () => {
    const url = `/?coupon=${selectedCoupon.code}`
    router.push(url)
    if (onClickBookButton) {
      onClickBookButton()
    }
  }

  return (
    <div
      className={cn(styles.container, styles[design])}
      data-cy="discountCoupon"
    >
      <div className={cn(styles.title, styles[design])}>
        {subject ||
          t('DiscountCoupon.title', '{{percentage}}% Flash Discount', {
            percentage: selectedCoupon.discountPercentage,
          })}
      </div>
      <div className={cn(styles.content, styles[design])}>
        {description ||
          t(
            'DiscountCoupon.content',
            'Quick! Use the code below within the next hour to get {{percentage}}% off any order sitewide!',
            {
              percentage: selectedCoupon.discountPercentage,
            },
          )}
      </div>
      <div className={styles.remainingTimeContainer}>
        <div className={styles.remainingTimeIcon}>
          <Image src={clockIcon} width={12} height={12} alt="clock" />
        </div>
        <div className={cn(styles.remainingTimeText, styles[design])}>
          {t(
            'DiscountCoupon.remainingTimeText2',
            'Book within {{remainingTimeFormatted}}',
            {
              remainingTimeFormatted,
            },
          )}
        </div>
      </div>
      <div className={cn(styles.couponCode, styles[design])}>
        <Text copyable>{selectedCoupon.code}</Text>
      </div>
      <div
        className={cn(styles.bookNowButton, styles[design])}
        onClick={onClick}
        role="button"
        tabIndex={0}
      >
        {t('DiscountCoupon.bookNowButton', 'Book Now!')}
      </div>
    </div>
  )
}

export default DiscountCoupon
