import { ApolloError, gql, useQuery } from '@apollo/client'
import type { CustomerCouponsQuery } from 'apollo-gql'
import { useAuth } from 'contexts/AuthContext'
import { currentMarketIdVar } from 'graphql/reactive'

import useAnonymousPermissions from 'hooks/useAnonymousPermissions'

const query = gql`
  query customerCoupons($customerId: ID) {
    customerCoupons(customerId: $customerId) {
      code
      expiresAt
      discountPercentage
    }
  }
`

type UseCustomerCoupons = () => {
  loading: boolean
  error: ApolloError
  coupons: CustomerCouponsQuery['customerCoupons']
}

const useCustomerCoupons: UseCustomerCoupons = () => {
  const marketId = currentMarketIdVar()
  const anonymousPermissions = useAnonymousPermissions()
  const customerId = anonymousPermissions?.customer?.[0]
  const { isAuthenticated } = useAuth()

  const { loading, error, data } = useQuery<CustomerCouponsQuery>(query, {
    skip: !customerId && !isAuthenticated,
    variables: {
      marketId,
      customerId,
    },
  })

  const coupons = data?.customerCoupons

  return { loading, error, coupons }
}

export default useCustomerCoupons
