import jwtDecode from 'jwt-decode'
import { getItem } from 'lib/localStorage'
import { isServer } from 'lib/utils'

type AnonymousPermission = {
  customer?: string[]
}

type UseAnonymousPermissions = () => AnonymousPermission | null

const useAnonymousPermissions: UseAnonymousPermissions = () => {
  const token = !isServer() && getItem('token')

  if (token) {
    const decodedToken = jwtDecode<{
      type: string
      anonymousPermissions: AnonymousPermission
    }>(token)
    const { anonymousPermissions, type } = decodedToken

    if (type === 'anonymous') {
      return anonymousPermissions
    }

    return null
  }

  return null
}

export default useAnonymousPermissions
