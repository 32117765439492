import React from 'react'
import cn from 'classnames'
import { withBasePath } from 'lib/utils'
import Image from 'next/image'

import styles from './Emoji.module.css'

const EMOJIS = [
  'baby',
  'birthday-cake',
  'blue-heart',
  'e-mail',
  'fire',
  'flag-ukraine',
  'flexed-biceps',
  'graduation-cap',
  'green-heart',
  'grinning-face',
  'laptop',
  'megaphone',
  'memo',
  'mobile-phone',
  'mobile-phone-with-arrow',
  'movie-camera',
  'party-popper',
  'partying-face',
  'pregnant-woman',
  'raising-hands',
  'red-heart',
  'rolling-on-the-floor-laughing',
  'santa-claus',
  'see-no-evil-monkey',
  'smiling-face-with-heart-eyes',
  'smiling-face-with-smiling-eyes',
  'smiling-face-with-sunglasses',
  'soccer-ball',
  'sparkles',
  'star-struck',
  'sun-with-face',
  'syringe',
  'television',
  'thinking-face',
  'thumbs-up',
  'video-camera',
  'wedding',
  'wrapped-gift',
] as const

export type EmojiType = typeof EMOJIS[number]

export type EmojiProps = {
  emoji: EmojiType
  className?: string
  alt?: string
}

/**
 * Replace emojis with pngs
 * @param emoji use emoji directly
 * @param className define style
 * If need more emojis, please do these:
 * 1. download from https://emojipedia.org/
 * 2. optimize png on https://tinypng.com/
 * 3. store image in static/emojis
 */
const Emoji: React.FC<EmojiProps> = ({ emoji, className, alt }) => {
  if (!EMOJIS.includes(emoji)) return null

  return (
    <div className={cn(styles.emojiWrapper, className)}>
      <Image
        layout="fill"
        src={withBasePath(`/static/emojis/${emoji}.png`)}
        alt={alt ?? emoji}
        unoptimized
      />
    </div>
  )
}

export default Emoji
