import dayjs from 'lib/dayjs'
import { useTranslation } from 'lib/i18n'

import useCountdown from 'hooks/useCountdown'

type UseRemainingTimeProps = {
  expiresAt: string
}

type UseRemainingTimeReturn = {
  remainingTimeFormatted: string
  days: number
  hours: number
  minutes: number
  seconds: number
}

const useRemainingTime = ({
  expiresAt,
}: UseRemainingTimeProps): UseRemainingTimeReturn => {
  const { t } = useTranslation()

  const timeleftMs = useCountdown(new Date(expiresAt || null).getTime())
  const timeleft = dayjs.duration(timeleftMs, 'milliseconds')

  const days = Math.floor(timeleft.asDays())
  const hours = Math.floor(timeleft.asHours()) % 24
  const minutes = timeleft.minutes()
  const seconds = timeleft.seconds()

  let remainingTimeFormatted

  if (days > 0) {
    remainingTimeFormatted = `${t('CampaignBadge.days', {
      defaultValue: '{{count}} day',
      defaultValue_plural: '{{count}} days',
      count: days,
    })} ${hours}:${minutes}:${seconds}`
  } else if (hours > 0) {
    remainingTimeFormatted = `${hours}:${minutes}:${seconds}`
  } else {
    remainingTimeFormatted = `${minutes}:${seconds}`
  }

  return { remainingTimeFormatted, days, hours, minutes, seconds }
}

export default useRemainingTime
