// Mirrored in api/lib/currencies.js

const cc = require('currency-codes')
const currencyFormatter = require('currency-formatter')

/*
  - IF you add a currency make sure it's included in this list of supported currencies from https://github.com/qqilihq/ecb-euro-exchange-rates
  - IF you want to accept payments in the currency also follow docs/other/Adding-a-Market.mdx

  USD: US dollar
  JPY: Japanese yen
  BGN: Bulgarian lev
  CZK: Czech koruna
  DKK: Danish krone
  GBP: Pound sterling
  HUF: Hungarian forint
  PLN: Polish zloty
  RON: Romanian leu
  SEK: Swedish krona
  CHF: Swiss franc
  ISK: Icelandic krona
  NOK: Norwegian krone
  HRK: Croatian kuna
  RUB: Russian rouble
  TRY: Turkish lira
  AUD: Australian dollar
  BRL: Brazilian real
  CAD: Canadian dollar
  CNY: Chinese yuan renminbi
  HKD: Hong Kong dollar
  IDR: Indonesian rupiah
  ILS: Israeli shekel
  INR: Indian rupee
  KRW: South Korean won
  MXN: Mexican peso
  MYR: Malaysian ringgit
  NZD: New Zealand dollar
  PHP: Philippine piso
  SGD: Singapore dollar
  THB: Thai baht
  ZAR: South African rand
*/

// This is just to be able to do Currency.SEK
const Currency = {
  CAD: 'CAD',
  DKK: 'DKK',
  EUR: 'EUR',
  NOK: 'NOK',
  SEK: 'SEK',
  USD: 'USD',
  GBP: 'GBP',
}

const availableCurrencies = Object.values(Currency)

const jsonSchemaCurrencies = (() => {
  const jsonSchema = {}
  availableCurrencies.forEach((currency) => {
    jsonSchema[currency] = { type: 'integer' }
  })
  return jsonSchema
})()

const getCurrency = (currency) => {
  const { code, currency: name, digits } = cc.code(currency)
  return {
    code,
    name,
    digits,
  }
}

const getCurrencySymbolFormat = (currency) => {
  // %s is the symbol and %v is the value
  if (
    currency === 'USD' ||
    currency === 'GBP' ||
    currency === 'CAD' ||
    currency === 'BRL'
  ) {
    return '%s%v'
  }
  return '%v %s'
}

const formatCurrency = ({ currency, valueInCent }) => {
  const hasDecimals = (valueInCent / 100) % 1 !== 0

  // @todo: Add locale (en_GB) for correct formatting for dots, spaces etc
  // @todo: Move special rule for CAD.
  return currencyFormatter.format(valueInCent / 100, {
    symbol: currency === 'CAD' ? 'C$' : undefined,
    code: currency,
    thousand: ' ',
    precision: hasDecimals ? undefined : 0, // Show no decimals unless it actually has decimals
    format: getCurrencySymbolFormat(currency),
  })
}

function formatAmount(result, currency, language) {
  return {
    valueInCent: result,
    currency,
    valueWithDecimals: (result / 100).toFixed(2),
    formattedValue: formatCurrency({
      currency,
      language,
      valueInCent: result,
    }),
  }
}

module.exports = {
  formatAmount,
  Currency,
  availableCurrencies,
  getCurrency,
  jsonSchemaCurrencies,
  formatCurrency,
}
